import React, { useLayoutEffect, useState } from "react";
import Logo from "../components/Logo";
import { useSearchParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import axios from "axios";
import NoMatch from "../components/NoMatch";
import Licence from "./Licence";
import AAdharVerification from "./AAdharVerification";
export let clientSecret = "";
export let token = "";


 const layoutObj = {
   "uuid": <AAdharVerification />,
   "dl": <Licence />
 }


export default function Layout() {
  const [loading, setLoading] = useState(true);
  const [queryParameters] = useSearchParams();
  const [validateToken, setValidateToken] = useState(false);
  const [componentLoaded, setComponentLoaded] = useState(null);
useLayoutEffect(() => {
  setLoading(true)
  const params = queryParameters.get('token');
  axios
    .post(`/api/v1/external/gateway/verify/token/`, {
      token: params,
    })
    .then(function (response) {
      token = params;
      clientSecret = response?.data?.data?.client_secret;
      setValidateToken(response?.status);
      setComponentLoaded(response?.data?.data?.verification_product);
    })
    .catch(function (error) {
      setValidateToken(error?.response?.data?.status);
    })
    .finally(() => {
      setLoading(false);
    });
}, []);




  return (
    <>
      <div className="content-wrapper">
        <div className="top-nav">
          <div style={{ width: "150px", height: "100%" }}>
            <Logo fill="#000" />
          </div>
        </div>
        <div className="content-body">
          {loading && !validateToken ? (
             <div className="loader">
                 <Spinner animation="border" variant="light" />
             </div>
          ) : validateToken ?  layoutObj[componentLoaded] : (
           <NoMatch />
          )}
          
        </div>
      </div>
    </>
  );
}
