import React from "react";
import Layout from "./src/container/Layout";
import * as bootstrap from "@styled-icons/bootstrap";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";



const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
  },
]);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
