import React, { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import FaceLiveness from "./FaceLiveness";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import axios from "axios";
import { clientSecret, token } from "./Layout";

const schema = yup
  .object({
    licenseNumber: yup
      .string()
      .matches(/^[a-zA-Z0-9]{10,15}$/)
      .required(),
    dob: yup
      .date()
      .max(new Date(), "Date of birth cannot be in the future")
      .min(
        new Date(new Date().setFullYear(new Date().getFullYear() - 120)),
        "Please provide a valid date"
      )
      .required("Date of birth is required"),
    consent: yup.boolean().oneOf([true]).required(),
  })
  .required();

export default function Licence() {
  const [image, setImage] = useState(null);
  const [verificationCompleted, setVerificationCompleted] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    // context: { isOtpRequired: otpRes && otpRes?.status },
    defaultValues: {
      licenseNumber: "",
      consent: false,
      dob: "",
    },
  });

  //Handle image after capture
  const handleImage = async (b64Image) => {
    setImage(b64Image);
  };

  const licenceSubmit = (data) => {
    setLoading(true);
    const date = data.dob;
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    axios
      .post(`/api/v1/external/gateway/verify/dl/`, {
        client_secret: clientSecret,
        token: token,
        image: image,
        doc_number: data?.licenseNumber,
        dob:formattedDate
      })
      .then(function (response) {
        toast.success(response?.data?.message);
        setVerificationCompleted(true);
      })
      .catch(function (error) {
        toast.error(error?.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {loading && (
        <div className="loader">
          <Spinner animation="border" variant="light" />
        </div>
      )}
      {verificationCompleted ? (
        <div className="no-match">
          <div className="text-center">
            <h1
              className="text-error"
              style={{
                textShadow: "none",
                lineHeight: "normal",
                fontSize: "4rem",
              }}
            >
              Your Verification has been completed...
            </h1>
          </div>
        </div>
      ) : !image ? (
        <FaceLiveness handleImage={handleImage} />
      ) : (
        <div className="wrapper">
          <div className="wrapper-sec">
            <div className="wrapper-title">Verify your Licence</div>
            <div className="wrapper=body">
              <Form onSubmit={handleSubmit(licenceSubmit)} noValidate>
                <Form.Group
                  className="mb-4"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>DL number</Form.Label>
                  <Controller
                    name="licenseNumber"
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        // maxLength={14}
                        placeholder="xxxx xxxx xxxx"
                        type="text"
                        autoComplete="new-password"
                        required
                      />
                    )}
                  />
                  <p style={{ color: "red", marginTop: "2px" }}>
                    {errors?.licenseNumber?.message.length > 0 &&
                      "Please provide valid license number"}
                  </p>
                </Form.Group>
                <Form.Group
                  className="mb-4"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Date of birth</Form.Label>
                  <Controller
                    name="dob"
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        placeholder="xxxx xxxx xxxx"
                        type="date"
                        autoComplete="new-password"
                        required
                      />
                    )}
                  />
                  <p style={{ color: "red", marginTop: "2px" }}>
                    {errors?.dob?.message.length > 0 &&
                      "Please provide the valid date of birth."}
                  </p>
                </Form.Group>
                <Form.Group className="mb-4" controlId="aadhar-chek">
                  <Controller
                    name="consent"
                    control={control}
                    render={({ field }) => (
                      <Form.Check
                        {...field}
                        checked={field.value}
                        type="checkbox"
                        label="I hereby consent to the use of License Number provided in the
          application for Identity Validation."
                        feedbackType="invalid"
                        isInvalid={!!errors.consent}
                      />
                    )}
                  />
                </Form.Group>
                <Button
                  type="submit"
                  variant="primary"
                  style={{ width: "100%" }}
                >
                  Verify now
                </Button>
              </Form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
